/*----------------------
         MEDIAQs 
------------------------*/


@media (max-width: 1200px) {

   .floatings {
      .lata {
         width: 250px;
      }

      .pelota {
         width: 300px;
      }
   }

}


@media (max-width: 910px) {
   .floatings {
      .lata {
         width: 200px;
      }

      .pelota {
         width: 220px;
      }
   }
}

@media (max-width: 800px) {
   .floatings {
      .lata {
         width: 150px;
      }

      .pelota {
         width: 180px;
      }
   }
}

@media (max-width: 630px) {


   .home-page {
      .info {
         .content-1 {
            .entradas {
               div {
                  &:first-child {
                     font-size: 5rem;
                  }

                  &:last-child {
                     font-size: 2rem;

                     span:last-child {
                        font-size: 2.5rem;
                     }
                  }
               }
            }

         }

         .content-2 {
            .pasos {
               .col-sm-4 {
                  margin-bottom: 2rem;
               }
            }
         }
      }
   }


   .footer {
      .links {
         flex-direction: column;
         align-items: center;

         li {
            margin-bottom: .6rem;

            &::after {
               content: none !important;
            }
         }
      }
   }

   .filebutton {
      font-size: 16px;
      margin-bottom: 1rem;
   }

   .camera {
      width: max-content;
      margin: 0 auto;
   }


}