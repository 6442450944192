@font-face {
   font-family: 'Gotham';
   src: url('./fonts/Gotham-Black.woff2') format('woff2'),
      url('./fonts/Gotham-Black.woff') format('woff');
   font-weight: 900;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'Gotham';
   src: url('./fonts/Gotham-Medium.woff2') format('woff2'),
      url('./fonts/Gotham-Medium.woff') format('woff');
   font-weight: 500;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'Gotham';
   src: url('./fonts/Gotham-Book.woff2') format('woff2'),
      url('./fonts/Gotham-Book.woff') format('woff');
   font-weight: normal;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'Gotham';
   src: url('./fonts/Gotham-Bold.woff2') format('woff2'),
      url('./fonts/Gotham-Bold.woff') format('woff');
   font-weight: bold;
   font-style: normal;
   font-display: swap;
}