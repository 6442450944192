/*----------------------
      GLOBAL COMPS 
------------------------*/

//loader
.overlay {
   display: flex;
   align-items: center;
   justify-content: center;
   position: fixed;
   z-index: 9998;
   top: 0;
   right: 0;
   left: 0;
   bottom: 0;

   &.white {
      background-color: rgba($color: #000, $alpha: .8);

      &.full {
         background-color: #000;
      }
   }
}

.spinner {
   width: 20px;
   height: 20px;
   animation: spinner-o824ag 1.2s infinite linear;
}

.spinner div {
   position: absolute;
   width: 100%;
   height: 100%;
   background: $amarillo;
   border-radius: 50%;
   animation: spinner-vse6n7 1.5s infinite ease;
}

.spinner div:nth-child(1) {
   --rotation: 90;
}

.spinner div:nth-child(2) {
   --rotation: 180;
}

.spinner div:nth-child(3) {
   --rotation: 270;
}

.spinner div:nth-child(4) {
   --rotation: 360;
}

@keyframes spinner-vse6n7 {

   0%,
   100% {
      transform: rotate(calc(var(--rotation) * 1deg)) translateY(0);
   }

   50% {
      transform: rotate(calc(var(--rotation) * 1deg)) translateY(300%);
   }
}

@keyframes spinner-o824ag {
   to {
      transform: rotate(360deg);
   }
}

//anim-link
.am-link {
   position: relative;
   text-decoration: none;
 }

 .am-link::before {
   content: "";
   position: absolute;
   bottom: -2px; /* Maintain a distance of -2px from the bottom */
   left: 50%; /* Position the underline at the horizontal center of the link */
   transform: translateX(-50%); /* Center the underline horizontally */
   width: 0;
   height: 2px; /* Adjust the thickness of the underline */
   background-color: #fff; /* Change the color of the underline */
   transition: width 0.3s ease-in-out;
   transform-origin: center; /* Set the transform origin to the center */
 }

 .am-link:hover::before {
   width: 100%;
 }

.CookieConsent {
   a {
      margin-left: 5px;
      color: #fff;
   }
}

//inputs
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}

/* Firefox */
input[type=number] {
   appearance: textfield;
   -moz-appearance: textfield;
}

/* Hide the default checkbox */
.sms-checkbox {
   display: none;
 }
 
 /* Style the custom checkbox */
 .sms-checkbox + label {
   position: relative;
   padding-left: 30px;
   cursor: pointer;
   font-size: 14px;
 }
 
 /* Checkbox icon */
 .sms-checkbox + label:before {
   content: "";
   position: absolute;
   top: 0;
   left: 0;
   width: 20px; 
   height: 20px; 
   border: 2px solid white; 
   background-color: white 
 }
 
 /* Checked checkbox icon */
 .sms-checkbox:checked + label:before {
   background-color: $amarillo;
   border-color: white;
 }
 
 /* Checkbox checkmark */
 .sms-checkbox:checked + label:after {
   content: "\2713";
   position: absolute;
   top: -1px;
   left: 5px;
   color: $negro;
 }
 
 /* Hover state */
 .sms-checkbox + label:hover:before {
   border-color: $negro;
 }

 //----------------------

.filebutton {
   font-weight: 500;
   font-size: 20px;
   cursor: pointer;
}

 .camera {
   position: relative;
   cursor: pointer;

   span {
      position: absolute;
      top: -8px;
      right: -3px;
      padding: 3px;

   }
 }

 //----------------------

 .floatings {
   position: relative;
   z-index: 2;

   .lata {
      width: 370px;
      position: absolute;
      top: -30vh;
      left: 6vw;
   }

   .pelota {
      position: absolute;
      top: -10vh;
      right: 5vw;
      width: 400px;
   }

   .bodegon-mobile {
      display: flex;
      align-items: center;
      justify-content: center;

      position: relative;
      top: -100px;
      margin-bottom: -150px;
   }
}